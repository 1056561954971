import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import RechargePlans from '../components/RechargePlans'
import API from '../api/api';

const OutOfWordsQuotaDialog = (props) => { 
  const [open, setOpen] = useState(props.open || false);
  const [openPlans, setOpenPlans] = useState(false);

  
  useEffect(() => {  
    if(props.open) 
      setOpen(true);

    //alert(props.open);
  }, [props.open]);
  
  const closeNotEnoughWordsDialog = () => {
    setOpen(false);
  }

  const openRechargePlans = () => {
    setOpen(false);
    setOpenPlans(true);
  }

  const onCloseRechargePlans = () => {
    setOpen(false);
    setOpenPlans(false);
  }

  const onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        //console.log(res)
      }
    )
  }

  return (
    <React.Fragment>
      <Dialog
        onClose={closeNotEnoughWordsDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, .5)'
          }
        }
        }
      >
        <DialogTitle id="customized-dialog-title" onClose={closeNotEnoughWordsDialog}>
          <Typography variant="h5" component="span">Not enough words left</Typography>
        </DialogTitle>
        <DialogContent>
          There are not enough words left to translate all fields in this section.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeNotEnoughWordsDialog} color="default" size="medium">
            Cancel
          </Button>
          {props.handleContinueTranslation && this.overflowIndex > -1 &&
            <Button variant="outlined" onClick={props.handleContinueTranslation} size="medium">
              translate available
            </Button>
          }
          <Button variant="contained" color="secondary" size="medium" onClick={openRechargePlans}>
            <TranslateOutlined size={20} style={{marginRight: 8}} /> Get more words
          </Button>
        </DialogActions>
      </Dialog>

      <RechargePlans
        visible={openPlans}
        onClose={onCloseRechargePlans}
        onBuy={onBuyWords}
      />
    </React.Fragment>
  );
}

export default OutOfWordsQuotaDialog;