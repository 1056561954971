import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Menu as MenuIcon } from '@material-ui/icons';


function TranslationFieldsPagination(props) {
  const classes = useStyles();

  const [page, setPage] = useState(props.currentPage || 1);
  const [totalPages, setTotalPages] = useState(Math.ceil(props.totalCount / props.perPage));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputPage, setInputPage] = useState(props.currentPage);

  useEffect(() => {
    setPage(props.currentPage);
    setInputPage(props.currentPage);
  }, [props.currentPage]);


  const handlePageChange = (event, value) => {
    setPage(value);
    props.subfieldsPaginateFunc[props.scope](props.itemId, value);
  };

  const handlePageInputChange = (e) => {
    const parsedPage = parseInt(inputPage, 10);
    if (!isNaN(parsedPage)) {
      if (parsedPage > 0 && parsedPage <= totalPages) {
        setPage(parsedPage);
        props.subfieldsPaginateFunc[props.scope](props.itemId, parsedPage);
      }
      else {
        setInputPage(page);
      }
    }
  };

  return (
    <div>
        {props.position == 'above' && <Divider className={classes.divider} />}
        <Toolbar variant="dense" className={classes.root}>
          <div className={classes.toolbarContent}>

            <span className={classes.label}>Current Page:</span>
            <TextField
              type="number"
              //label="Page Number"
              value={inputPage}
              onBlur={handlePageInputChange}
              onChange={(e) => setInputPage(e.target.value)}
              inputProps={{ min: 1 }}
              margin="dense"
              size="small"
              InputProps={{
                classes: {
                  input: classes.inputElement, // Add custom class to the input element
                },
              }}
            />
            <Pagination className={classes.pagination} count={totalPages} page={page} onChange={handlePageChange} color="default" />
          </div>
        </Toolbar>
        {props.position == 'below' && <Divider className={classes.divider} />}
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  label: {
    marginRight: theme.spacing(2), // Adjust the spacing between label and input
    fontSize: 14
  },
  title: {
    flexGrow: 1,
  },
  toolbarContent: {
    display: 'flex', 
    alignItems: 'center',
    padding: 4,
    marginLeft: 'auto', // Align right
  },
  pagination: {
    display: 'inline-flex',
    marginLeft: 100
  },
  inputElement: {
    fontSize: 14,
    width: 50,
    padding: 0
  },
  divider: {
    margin: '0 24px'
  }
}));

export default TranslationFieldsPagination;