import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import createRootReducer from './modules'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]


// Sentry integration
if (process.env.NODE_ENV === 'production') {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });
  Sentry.init({
    dsn: "https://1a8017b80aee560e48a563c007f2bb02@o4508556528189440.ingest.de.sentry.io/4508557597147216",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });

  enhancers.push(sentryReduxEnhancer);
}

// Redux Dev Tools 
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)
