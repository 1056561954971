import React, { useState, useEffect } from 'react';
import { enableCrossDomainLinks, disableCrossDomainLinks } from '../../modules/shop'
import { getPlans, changePlan } from '../../modules/plans'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withRouter from 'with-router'
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TileRow from './TileRow';
import ThemesSection from './ThemesSection';
import SimpleLineChart from './statistics/SimpleLineChart';
import SimpleTable from './statistics/SimpleTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import TranslationProgressSection from './TranslationProgressSection';
import RecentlyUpdatedContentSection from './RecentlyUpdatedContentSection';
import LanguagesSection from './LanguagesSection';
import SwitcherConfigurator from '../../components/SwitcherConfigurator';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import DontAskAgainDialog from '../../components/DontAskAgainDialog.js';
import LoadingIndicator from '../../components/LoadingIndicator';
import API from '../../api/api';
import ErrorBoundary from '../../components/ErrorBoundary';
import UpdateRequiredNotice from '../../components/UpdateRequiredNotice';
import WelcomeTile from './WelcomeTile';
import UpdateAppPermissionsDialog from '../../components/UpdateAppPermissionsDialog';
import NewsletterSubscriptionDialog from '../../components/NewsletterSubscriptionDialog.js';
import { SwitcherConfiguratorDummy, ThemeSettingsDummy } from '../../components/PricingPlans.js';


const REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR = window.loadEnv("REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR", process.env.REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR);
const REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS = window.loadEnv("REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS", process.env.REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS);


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      welcomeTileClosed: window.localStorage.getItem('ly_welcome_closed'),
      randomHash: '',
    }
  }

  componentDidMount() {
    this.props.getPlans(() => {
      
    })
  }

  onClosedOnboarding = () => {
    window.localStorage.setItem('ly_welcome_closed', true);
    this.setState({
      welcomeTileClosed: true,
    })
  }

  onEnableCrossDomainLinks = () => {
    this.props.enableCrossDomainLinks();
  }

  onDisableCrossDomainLinks = () => {
    this.props.disableCrossDomainLinks();
  }

  handleThemeSectionRerender = () => {
    setTimeout(() => {
      let r = (Math.random() + 1).toString(36).substring(7);
      this.setState({randomHash: r});
    }, 100);
  }

  _isPastDate(dateString, days) {
    var givenDate = new Date(dateString);
    givenDate.setDate(givenDate.getDate() + days);
    var currentDate = new Date();
    if (currentDate < givenDate) {
      return false; // Given date is in the future
    } else {
      return true; // Given date is in the past or today
    }
  }

  render() {
    const { classes, shop } = this.props;

    const isNewMerchant = shop.charge ? this._isPastDate(shop.charge.created_at, shop.charge.trialDays) ? false : true : false;

    return (
      <React.Fragment>

        {/* this.state.ly_theme_caching_bug_warning !== true && localStorage.getItem('ly_theme_caching_bug_warning') !== 'true' &&
          <Alert severity="warning"
            elevation={3}
            onClose={() => {
              localStorage.setItem('ly_theme_caching_bug_warning', true);
              this.setState({'ly_theme_caching_bug_warning': true});
            }}
            gutterBottom
            variant="outlined"
            style={{marginBottom: 16, fontSize: 16, backgroundColor: 'white'}} 
          >
            <strong>WARNING!</strong><br/>
            There is currently a technical delay on Shopify's side that may cause <strong>changes made through our app to take up to 10 minutes</strong> to become visible in the store.<br/>
            This can affect actions like publishing and unpublishing langify, changing translations or editing the language switcher settings.<br/>
            The Shopify development team is aware of the issue and working on the solution. We are sorry for the inconvenience.
          </Alert>
        */}

        {shop.messages && shop.messages.map((message) => {
          return (
            this.state[message.key] !== true && localStorage.getItem(message.key) !== 'true' &&
            <Alert severity={message.severity ? message.severity : 'warning'}
              elevation={3}
              onClose={message.closable ? () => {
                localStorage.setItem(message.key, true);
                this.setState({ key: message.key, value: true });
              } : null}
              gutterBottom
              id={message.key}
              variant={message.variant ? message.variant : 'outlined'}
              style={{ marginBottom: 16, fontSize: 16, ...message.style }}
            >
              {message.title && 
                <AlertTitle dangerouslySetInnerHTML={{ __html: message.title }}></AlertTitle>
              }
              {message.body && 
                <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
              }
              {message.styleTag && (
                <style>
                  {message.styleTag}
                </style>
              )}
            </Alert>
          );
        })}

        {/*<UpdateRequiredNotice 
          {...this.props}
          requiredVersions={{
            'snippets/ly-core-scripts.liquid': '3.6.0',
            'snippets/ly-switcher-factory.liquid': '3.4.0',
          }} 
          moreInfoContent={<React.Fragment>
            <br />
            <strong>The following features and functions won't work correctly until the update is done:</strong>
            <ul>
              <li>Change and save the switcher and language-detection configuration via the "Switcher Configurator".</li>
              <li>Create new Custom Contents</li>
            </ul>
            <strong>The following files will be affected by the update:</strong>
            <ul>
              <li>snippets/ly-core-scripts.liquid</li>
              <li>snippets/ly-get-customs.liquid</li>
              <li>snippets/ly-switcher-factory.liquid</li>
            </ul>  
          </React.Fragment>}
          />*/}

        <UpdateAppPermissionsDialog marginBottom/>

        <Typography variant="h4" gutterBottom component="h2">
          Dashboard
        </Typography>
        <Divider className="divider-white" />
        <div className={classes.contentContainer}>
          {!this.state.welcomeTileClosed && isNewMerchant ?
            <WelcomeTile onClose={this.onClosedOnboarding} />
          :
            <TileRow shop={this.props.shop} />
          }
        </div>

        {this.props.shop.edit_language && this.props.shop.edit_language !== 'Language' && (
          <Grid container alignItems="stretch" alignContent="stretch" spacing={3}>
            <Grid item xs={12} md={6} style={{paddingTop:0}}>
              <TranslationProgressSection editLanguage={this.props.shop.edit_language} editTheme={this.props.editTheme}/>
            </Grid>
            <Grid item xs={12} md={6} style={{paddingTop:0}}>
              <RecentlyUpdatedContentSection />
            </Grid>
          </Grid>          
        )}

        <ErrorBoundary msg='Whoops, an error occured in the "Languages" section. Please try to reload this page or contact our support if the problem persists.'>
          <LanguagesSection />
        </ErrorBoundary>

        {REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR == 'true' &&
          <ErrorBoundary msg='Whoops, an error occured in the "Switcher Configurator" section. Please try to reload this page or contact our support if the problem persists.'>
            {this.props.shop.charge.name === "free" ?
              <SwitcherConfiguratorDummy />
            :
              <SwitcherConfigurator
                key={`switcher-section-${this.props.editTheme}`}
                onEnableCrossDomainLinks={this.onEnableCrossDomainLinks}
                onDisableCrossDomainLinks={this.onDisableCrossDomainLinks}
              />  
            }          
          </ErrorBoundary>
        }
        {REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS == 'true' && 
          <ErrorBoundary msg='Whoops, an error occured in the "Theme Settings" section. Please try to reload this page or contact our support if the problem persists.'>
            {this.props.shop.charge.name === "free" ?
              <ThemeSettingsDummy />
            :
              <ThemesSection 
                key={`theme-section-${this.props.editTheme}-${this.state.randomHash}`} 
                rerender={this.handleThemeSectionRerender}
              />
            }
          </ErrorBoundary>
        }


        <NewsletterSubscriptionDialog />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

const mapStateToProps = ({ shop, themes }) => ({
  shop_version: shop.shop.version,
  shop: shop.shop,
  editTheme: themes.edit_theme,
  isPublished: themes.is_published,
  themes: themes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enableCrossDomainLinks,
      disableCrossDomainLinks,
      getPlans
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard)))