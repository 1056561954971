import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddOutlined from '@material-ui/icons/AddOutlined';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import TranslationFields from './TranslationFields';
import TranslationFieldsPagination from './TranslationFieldsPagination.js';
import { UpgradePlanHint } from './PricingPlans';
import LoadingIndicator from '../components/LoadingIndicator';

class CollapsableFields extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      showInfo: true,
      collapsed: true,
      updateNumber: 0,
    };
  }


  componentWillReceiveProps = (newProps) => {
    //console.log(newProps.forceOpen, this.props.parentField)

    if(this.props.forceUpdate !== newProps.forceUpdate && newProps.forceOpen === this.props.parentField) {
      //alert(this.props.forceOpen)
      //if(this.props.forceOpen !== newProps.forceOpen) {
        this.handleToggleCollapse(null);
      //}
    }

    if(this.props.isSubfieldsPending === true && newProps.isSubfieldsPending === false) {
      //this.setState({updateNumber: Date.now()})

      this.forceUpdate();
    }
  }

  handleClick = (evt) => {
    this.setState({
      showInfo: false
    })
  }

  handleToggleCollapse = (evt) => {
    if(this.props.disabled) return null;

    if(this.state.collapsed === true) {
      if(this.props.onExpand) {
        this.props.onExpand(this.props.id)
      }
    }

    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  handleChange = (event, data) => {
    this.props.onChange(null, {
      ...data,
      parentField: this.props.id
    });
  }

  getPaginatedFields = (fields, currentPage, totalCount) => {
    let paginatedFields = [];
    let startIndex = (currentPage-1)*100;
    let endIndex = startIndex+100;
    if(endIndex > totalCount) {
      endIndex = totalCount;
    }
    for(let i=startIndex; i<endIndex; i++) {
      console.log(i, fields[i])
      paginatedFields.push(fields[i]);
    }
    return paginatedFields;
  }

  render() {
    const {
      classes,
      fields,
      isFirst,
      parentField,
      onSubfieldsExpand,
      onSubfieldsExpanded,
      onSubfieldsCollapsed,
      id,
      itemId,
      paginated,
      currentPage,
      totalCount,
      subfieldsPaginateFunc
    } = this.props;

    const {
      showInfo,
      collapsed,
    } = this.state;

    return (
      <div className={classNames('collapsable-fields', isFirst && 'first', this.props.type, this.props.id, classes.root)}>
        <Divider className={classes.divider} />

        <Typography className={classNames(classes.label, this.props.disabled ? 'disabled' : null)} variant="h5" component="p" onClick={this.handleToggleCollapse}>
          {collapsed ? <AddOutlined fontSize={'small'} className={classes.toggleIcon} /> : <RemoveOutlined fontSize={'small'} className={classes.toggleIcon} />}
          {this.props.label}
        </Typography> 
        {this.props.locked &&
          <UpgradePlanHint neededPlan={'basic'} position="end" placement="bottom" top={8} isOverlay />
        }

        <Collapse 
          in={!collapsed}
          mountOnEnter={true}
          unmountOnExit={true}

          onEnter={onSubfieldsExpand ? onSubfieldsExpand : null}
          onEntered={onSubfieldsExpanded ? onSubfieldsExpanded : null}
          onExited={onSubfieldsCollapsed ? onSubfieldsCollapsed : null}
        >
          <div className={classNames(classes.collapseContainer, `id-${this.props.id}`, `type-${this.props.type}`)}>
            <Divider className={classes.divider} />


            {paginated && this.props.totalCount > 100 &&
              <TranslationFieldsPagination 
                scope={id}
                itemId={itemId}
                perPage={100}
                totalCount={totalCount}
                currentPage={currentPage}
                subfieldsPaginateFunc={this.props.subfieldsPaginateFunc} 
                position="below"
              />
            }

            <TranslationFields 
              //key={`translationfields-${this.props.type}-${this.props.id}-${this.state.updateNumber}`}
              data={paginated ? this.getPaginatedFields(fields, currentPage, totalCount) : fields} 
              nested
              itemId={this.props.itemId}
              id={this.props.id}
              parentField={parentField}
              isPending={this.props.isPending} 
              isSubfieldsPending={this.props.isSubfieldsPending}
              onChange={this.handleChange} 
              from={this.props.from}
              to={this.props.to}
              forceUpdate={this.props.forceUpdate}
              triggerClearAllTranslations={this.props.triggerClearAllTranslations}
              triggerDiscardChanges={this.props.triggerDiscardChanges}
              emptyMessage={this.props.emptyMessage}


              paginated={paginated}
              totalCount={totalCount}
              currentPage={currentPage}
              subfieldsPaginateFunc={subfieldsPaginateFunc}
            />  

            {paginated && totalCount > 100 &&
              <TranslationFieldsPagination 
                scope={id}
                itemId={itemId}
                perPage={100}
                totalCount={totalCount}
                currentPage={currentPage}
                subfieldsPaginateFunc={subfieldsPaginateFunc} 
                position="above"
              />
            }
            
            {this.props.isSubfieldsPending && 
              <div className={classes.overlay}>
                {/*<CircularProgress size={20} />*/}
              </div>
            }   
               
            {/*<LoadingIndicator
              pending={this.props.isSubfieldsPending}
              withLogo
            />*/}
          </div>
        </Collapse>

      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginLeft: '-24px',
    marginRight: '-24px',
    backgroundColor: '#fafafa',
    position: 'relative'
  },
  collapseContainer: {
    '&.id-images .static-list': {
      display: 'flex',
      overflow: 'auto',
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      padding: '24px',
    }
  },
  divider: {
    //margin: '0 -24px',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'rgba(255,255,255,.5)',
    display: 'flex',
    //alignItems: 'center',
    justifyContents: 'center',
    marginTop: 100,
  },
  label: {
    color: '#555',
    cursor: 'pointer',
    padding: '12px 24px',
    fontSize: '1rem',
    '&.disabled': {
      opacity: .5,
      cursor: 'default'
    }
  },
  toggleIcon: {
    margin: '-2px 12px 0 0'
  },
  cta: {
    margin: '12px 0',
    padding: '24px 16px',
    textAlign: 'center',
    cursor: 'pointer',
    border: 'dashed 1px rgb(92, 107, 192)',
    backgroundColor: 'rgb(235, 237, 247)',
    '& p': {
      color: 'rgb(92, 107, 192)',
    },
    '& span': {
      color: 'rgb(92, 107, 192)',
    },
  },
});

export default withStyles(styles)(CollapsableFields);