import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Collapse,
  Slide
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Helmet } from 'react-helmet';



const FormOuterContent = `
  <div id="error-message" class="sib-form-message-panel" style="font-size:16px; text-align:left; font-family:Helvetica, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949; max-width:350px; margin: 0;">
    <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
      <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
        <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
      </svg>
      <span class="sib-form-message-panel__inner-text">
        Your subscription could not be saved. Please try again.
      </span>
    </div>
  </div>
  <div id="success-message" class="sib-form-message-panel" style="font-size:16px; text-align:left; font-family:Helvetica, sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66; max-width:350px; margin: 0;">
    <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
      <svg viewBox="0 0 512 512" class="sib-icon sib-notification__icon">
        <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
      </svg>
      <span class="sib-form-message-panel__inner-text">
          Your subscription has been successful.
      </span>
    </div>
  </div>`;


const FormInnerContent = `<div style="padding: 8px 0;">
<div class="sib-input sib-form-block">
  <div class="form__entry entry_block">
    <div class="form__label-row ">
      <label class="entry__label" style="font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:Helvetica, sans-serif; color:#3c4858;" for="EMAIL" data-required="*">Enter your email address to subscribe</label>

      <div class="entry__field">
        <input class="input " type="text" id="EMAIL" name="EMAIL" autocomplete="off" placeholder="EMAIL" data-required="true" required />
      </div>
    </div>

    <label class="entry__error entry__error--primary" style="font-size:16px; text-align:left; font-family:Helvetica, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
    </label>
    <label class="entry__specification" style="font-size:12px; text-align:left; font-family:Helvetica, sans-serif; color:#8390A4; text-align:left">
      Provide your email address to subscribe. For e.g abc@xyz.com
    </label>
  </div>
</div>
</div>
<div style="padding: 8px 0;">
<div class="sib-optin sib-form-block">
  <div class="form__entry entry_mcq">
    <div class="form__label-row ">
      <div class="entry__choice" style="">
        <label>
          <input type="checkbox" class="input_replaced" value="1" id="OPT_IN" name="OPT_IN" />
          <span class="checkbox checkbox_tick_positive"
  style="margin-left:"
  ></span><span style="font-size:14px; text-align:left; font-family:Helvetica, sans-serif; color:#3C4858; background-color:transparent;"><p>I agree to receive your newsletters and accept the data privacy statement.</p></span> </label>
      </div>
    </div>
    <label class="entry__error entry__error--primary" style="font-size:16px; text-align:left; font-family:Helvetica, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
    </label>
    <label class="entry__specification" style="font-size:12px; text-align:left; font-family:Helvetica, sans-serif; color:#8390A4; text-align:left">
      You may unsubscribe at any time using the link in our newsletter.
    </label>
  </div>
</div>
</div>
<div style="padding: 8px 0;">
<div class="sib-form-block sib-divider-form-block">
  <div style="border: 0; border-bottom: 1px solid #E5EDF6"></div>
</div>
</div>
<div style="padding: 8px 0;">
<div class="sib-form__declaration" style="direction:ltr">
  <div class="declaration-block-icon">
    <svg class="icon__SVG" width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <symbol id="svgIcon-sphere" viewBox="0 0 63 63">
          <path class="path1" d="M31.54 0l1.05 3.06 3.385-.01-2.735 1.897 1.05 3.042-2.748-1.886-2.738 1.886 1.044-3.05-2.745-1.897h3.393zm13.97 3.019L46.555 6.4l3.384.01-2.743 2.101 1.048 3.387-2.752-2.1-2.752 2.1 1.054-3.382-2.745-2.105h3.385zm9.998 10.056l1.039 3.382h3.38l-2.751 2.1 1.05 3.382-2.744-2.091-2.743 2.091 1.054-3.381-2.754-2.1h3.385zM58.58 27.1l1.04 3.372h3.379l-2.752 2.096 1.05 3.387-2.744-2.091-2.75 2.092 1.054-3.387-2.747-2.097h3.376zm-3.076 14.02l1.044 3.364h3.385l-2.743 2.09 1.05 3.392-2.744-2.097-2.743 2.097 1.052-3.377-2.752-2.117 3.385-.01zm-9.985 9.91l1.045 3.364h3.393l-2.752 2.09 1.05 3.393-2.745-2.097-2.743 2.097 1.05-3.383-2.751-2.1 3.384-.01zM31.45 55.01l1.044 3.043 3.393-.008-2.752 1.9L34.19 63l-2.744-1.895-2.748 1.891 1.054-3.05-2.743-1.9h3.384zm-13.934-3.98l1.036 3.364h3.402l-2.752 2.09 1.053 3.393-2.747-2.097-2.752 2.097 1.053-3.382-2.743-2.1 3.384-.01zm-9.981-9.91l1.045 3.364h3.398l-2.748 2.09 1.05 3.392-2.753-2.1-2.752 2.096 1.053-3.382-2.743-2.102 3.384-.009zM4.466 27.1l1.038 3.372H8.88l-2.752 2.097 1.053 3.387-2.743-2.09-2.748 2.09 1.053-3.387L0 30.472h3.385zm3.069-14.025l1.045 3.382h3.395L9.23 18.56l1.05 3.381-2.752-2.09-2.752 2.09 1.053-3.381-2.744-2.1h3.384zm9.99-10.056L18.57 6.4l3.393.01-2.743 2.1 1.05 3.373-2.754-2.092-2.751 2.092 1.053-3.382-2.744-2.1h3.384zm24.938 19.394l-10-4.22a2.48 2.48 0 00-1.921 0l-10 4.22A2.529 2.529 0 0019 24.75c0 10.47 5.964 17.705 11.537 20.057a2.48 2.48 0 001.921 0C36.921 42.924 44 36.421 44 24.75a2.532 2.532 0 00-1.537-2.336zm-2.46 6.023l-9.583 9.705a.83.83 0 01-1.177 0l-5.416-5.485a.855.855 0 010-1.192l1.177-1.192a.83.83 0 011.177 0l3.65 3.697 7.819-7.916a.83.83 0 011.177 0l1.177 1.191a.843.843 0 010 1.192z" fill="#0092FF"></path>
        </symbol>
      </defs>
    </svg>
    <svg class="svgIcon-sphere" style="width:63px; height:63px;">
      <use href="#svgIcon-sphere"></use>
    </svg>
  </div>
  <div style="font-size:14px; text-align:left; font-family:Helvetica, sans-serif; color:#687484; background-color:transparent;">
    <p>We use Brevo as our marketing platform. By submitting this form you agree that the personal data you provided will be transferred to Brevo for processing in accordance with <a href="https://www.brevo.com/en/legal/privacypolicy/" target="_blank">Brevo's Privacy Policy.</a></p>
  </div>
</div>
</div>
<div style="padding: 8px 0;">
<div class="sib-form-block sib-divider-form-block">
  <div style="border: 0; border-bottom: 1px solid #E5EDF6"></div>
</div>
</div>
<div style="padding: 8px 0;">
<div class="sib-form-block" style="text-align: left">
  <button class="sib-form-block__button sib-form-block__button-with-loader" style="font-size:16px; text-align:left; font-family:Helvetica, sans-serif; color:#FFFFFF; background-color:#3E4857; border-radius:3px; border-width:0px;" form="sib-form" type="submit">
    <svg class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon" viewBox="0 0 512 512">
      <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
    </svg>
    SUBSCRIBE
  </button>
</div>
</div>
<input type="text" name="email_address_check" value="" class="input--hidden" />
<input type="hidden" name="locale" value="en" />`;


const BrevoForm = ({ formURL, onSuccess, onError }) => {
  const captchaScriptId = 'recaptcha-script'
  const mainScriptId = 'main-script'

  const containerRef = useRef(null); 
  const formRef = useRef(null); 

  const loadScript = (scriptId, src) => {
    let script = document.getElementById(scriptId)
    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.defer = true
      script.id = scriptId
      document.body.appendChild(script)
    } else {
      // Update the script src to force reload if it's already present
      script.src = src
    }
  }

  const unloadScript = (scriptId) => {
    const script = document.getElementById(scriptId)
    if (script) {
      script.remove()
    }
  }

  const loadRecaptchaScript = () => {
    const src = `https://www.google.com/recaptcha/api.js`
    loadScript(captchaScriptId, src)
  }

  const loadMainScript = () => {
    const src = `https://sibforms.com/forms/end-form/build/main.js`
    loadScript(mainScriptId, src)
  }

  useEffect(() => {
    loadRecaptchaScript()
    loadMainScript()




    const handleMutations = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const successMessage = mutation.target.closest('#success-message');
          const errorMessage = mutation.target.closest('#error-message');

          if (successMessage) {
            console.log('Erfolg:', successMessage.textContent);
            if (formRef.current) {
              formRef.current.style.display = 'none';
            }
            onSuccess(successMessage.textContent);
          }

          if (errorMessage) {
            console.log('Fehler:', errorMessage.textContent);
            onError(errorMessage.textContent);
          }
        }
      });
    };

    // Observer erstellen
    const observer = new MutationObserver(handleMutations);

    // Den Container überwachen, wenn die Referenz existiert
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        attributes: true,
        childList: true, // Beobachtet Änderungen an den direkten Kindern
        subtree: true, // Beobachtet Änderungen im gesamten Unterbaum
      });
    }


    return () => {
      unloadScript(captchaScriptId)
      unloadScript(mainScriptId)


      observer.disconnect();
    }
  }, [])


return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://sibforms.com/forms/end-form/build/sib-styles.css"
        />
      </Helmet>

      <div id="sib-form-container" ref={containerRef}>
        <div dangerouslySetInnerHTML={{ __html: FormOuterContent }} />

        <form
          id="sib-form"
          method="POST"
          action={formURL}
          data-type="subscription"
          ref={formRef}
        >
          <div dangerouslySetInnerHTML={{ __html: FormInnerContent }} />
                  
        </form>
      </div>
    </>
  )
}


const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    pointerEvents: 'none', // Allow clicks to pass through
  },
  dialogContainer: {
    //pointerEvents: 'none', // Allow clicks to pass through
  },
  dialogPaper: {
    pointerEvents: 'auto', // Ensure dialog itself is still interactive
    position: 'absolute',
    bottom: 24,
    left: 24,
    margin: 0,
  },
  dialogContent: {
    position: 'relative',
    textAlign: 'center',
    '& img': {
      width: '100%',
      filter: 'hue-rotate(20deg)'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer',
    zIndex: 1
  }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 800, exit: 500 }} />;
});

const NewsletterSubscriptionDialog = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const isClosed = window.localStorage.getItem('ly-newsletter-subscription-dismissed');
    if (!isClosed) {
      setTimeout(() => {
        setOpen(true);
      }, 10000);
    }

    if(showForm) {
      // Find the Sendinblue form by its container class or ID
      const sibForm = document.querySelector("#sib-form"); // Replace with your form's class or ID

      if (sibForm) {
        // Listen for submission results
        sibForm.addEventListener("submit", (e) => {
          console.log("Form submitted:", e); // Inspect the event object
        });

        // Check for custom events from Sendinblue
        sibForm.addEventListener("formSubmitted", (e) => {
          console.log("Form submitted successfully:", e.detail); // Handle successful submission
        });

        sibForm.addEventListener("formError", (e) => {
          console.error("Form submission error:", e.detail); // Handle errors
        });
      } else {
        console.error("Sendinblue form not found.");
      }
    }
  }, [showForm]);

  
  const handleDismiss = () => {
    window.localStorage.setItem('ly-newsletter-subscription-dismissed', true);
    setOpen(false);
  };

  const handleSuccess = () => {
    setTimeout(() => {
      handleDismiss();
    }, 5000);
  }

  const handleError = () => {
    
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="cookie-dialog-title"
      classes={{
        root: classes.dialogRoot, 
        container: classes.dialogContainer, 
      }}
      TransitionComponent={Transition}
      BackdropProps={{ style: { backgroundColor: 'transparent', pointerEvents: 'none' } }} 
      hideBackdrop={true}
      PaperProps={{
        className: classes.dialogPaper,
        classes: { root: classes.dialogPaper },
        style: {
          position: 'absolute',
          bottom: 24,
          left: 24,
          padding: '0 0 12px',
          width: '400px', // Customize width if needed
        },
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <CloseIcon 
          className={classes.closeButton}
          onClick={handleDismiss}
        />
        {!showForm &&
          <>
            <img src={'/files/images/nlsubscribe.webp'} />
            <Typography variant="h5">Be the first one to know!</Typography>
            <Typography variant="h6" style={{marginBottom: 8}}>...with our <strong>newsletter</strong></Typography>
            <Button onClick={() => setShowForm(true)} color="secondary" variant="contained">
              Subscribe
            </Button>
          </>
        }

        <Collapse in={showForm}>
          <div>
            <BrevoForm 
              formURL={'https://2d740fe3.sibforms.com/serve/MUIFAOf5-c9uqIfi9bgF-4sjJk1UxXK6Fu9Meb33cK7TsEvkjTfaxcjMi4mHHOT-MgaP9QeI4U1R_wrArLpTiBGiPxrbLjrtCxfjsp_sD9-rWbZT_-gVY4lmd_WWsDev3udI5fyzbqKkbBmEeJo9YwKBu6yl3pfvj-Dw2BDcZbf5aIOx3yUCD2X1JDoPojLxWjSsnRr4XR8OoDyJ'} 
              onSuccess={handleSuccess}
              onError={handleError}
            />

            {/*
            <iframe 
              width="350" 
              height="305" 
              src="https://2d740fe3.sibforms.com/serve/MUIFAJqI9i3-uqu3sSfeCrj7zb9r2snbj1I_m5XWvc8Of9GePUaayKKFbq_eJvqso79tXGxLw6nvaVaVAUtxhXI2McQ-81PRlJHgQAedQdgsVDgQZLwLTVipuVLyVL3p-ijzc_sto6wVs9Zh9rALRV934X3M4U--ctr7Y13ZHbQfffcpjjx9d_3M3B58q5HktkXSeg4ykgk5bWdr" 
              frameborder={0} 
              //scrolling="auto" 
              //allowfullscreen 
              //</React.Fragment>style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"
            >
              <style>
                {`
                  .sib-form { padding: 0 !important; }
                  #sib-container { padding: 0 !important; }
                `}
              </style>
            </iframe>
            */}
          </div>        
        </Collapse>

      </DialogContent>
    </Dialog>
  );
};

export default NewsletterSubscriptionDialog;
