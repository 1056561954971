import React, { Component } from 'react'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUpOutlined';
import AddOutlined from '@material-ui/icons/AddOutlined';
import RemoveOutlined from '@material-ui/icons/RemoveOutlined';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { SnackbarContent, withSnackbar } from 'notistack';
import store from '../store';



import { ReactComponent as Logo } from '../containers/design/logo-icon.svg';
import { Button, Collapse } from '@material-ui/core';


/****
 * 
 * ERROR LEVELS
 * 
 * 0 => Show Whoops
 * 1 => Show Whoops + Close currently active/open Item.
 * 2 => Show Whoops + Close open Subfields section and leave the active Item opened.
 * 3 => No Whoops and show the error in a Snackbar
 */

class LoadingIndicator extends Component {

  constructor(props) {
    super(props);

    const t = props.t;

    this.state = {
      loadingStr: `${props.loadingType ? t(`system.${props.loadingType}`) : t('system.loading')}\u00A0\u00A0\u00A0\u00A0`,
      loadingType: props.loadingType ? props.loadingType : 'loading', 
      showError: false,
      showErrorDetail: false,
    }
  }

  componentDidMount() {
    const { t } = this.props;
    let tick = 0;
    this.clock = setInterval(() => {
      this.setState({
        loadingStr: `${this.props.loadingType ? t(`system.${this.props.loadingType}`) : t('system.loading')}${'.'.repeat(tick)}${'\u00A0'.repeat(4-tick)}`,
      })
      tick += 1;
      if(tick == 5) tick = 0; 
    }, 300)
  }

  componentWillUnmount() {
    clearInterval(this.clock);
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;
    if(this.props.error === false && typeof newProps.error === 'object') {
      if(newProps.error?.level === 3) {
        this.props.enqueueSnackbar(newProps.error.message, {
          variant: 'error',
          persist: true,
          action: (key) => <Button size='small' onClick={() => this.props.closeSnackbar(key)} style={{ color: 'white' }}>
            {t('system.close')}
          </Button>
        });
      }
      else {
        this.setState({ showError: true });
      }
    }
  }

  handleErrorCancel = () => {
    this.setState({
      showError: false,
    });
    if(this.props.onErrorCancel) {
      this.props.onErrorCancel();
    }
  }

  handleToggleErrorDetail = () => {
    this.setState({
      showErrorDetail: !this.state.showErrorDetail,
    });
  }

  handleErrorRetry = () => {
    this.setState({
      showError: false,
    });
    if(this.props.onErrorRetry) {
      this.props.onErrorRetry();
    }
    if(typeof this.props.error.action === 'function') {
      store.dispatch(this.props.error.action());
    }
  }

  render() {
    const { t, classes, styles, pending, progress, withLogo, message, error } = this.props;
    const { showError, showErrorDetail } = this.state;

    return (
      <React.Fragment>
        <Fade
          in={pending || showError}
          style={{
            transitionDelay: pending ? '0ms' : '0ms',
            //display: this.props.transparent ? 'none' : 'initial'
          }}
          unmountOnExit
        >
          <div id="loadingIndicator" className={classNames(this.props.loadingType === 'initializing' ? 'initializing' : classes.root)}>
            <div className={classes.placeholder}>
              <Fade 
                in={pending || showError}
                style={{
                  transitionDelay: pending ? '400ms' : '0ms',
                }}
                unmountOnExit
              >
                {!showError ?
                  <React.Fragment>
                    {withLogo ?
                      <Logo />
                    :
                      <CircularProgress classes={{root: (styles && styles.circularProgress) ? styles.circularProgress : classes.circularProgress}} />
                    }

                    {message ?
                      <Typography variant='h5'>{message}</Typography>
                    :
                      <Typography variant="subtitle1" color="initial" style={{marginTop: 16}}>{progress ? `${progress}% ${this.state.loadingStr}` : this.state.loadingStr}</Typography>
                    }                 
                  </React.Fragment>
                :
                  <React.Fragment>
                    <Typography variant="h4" color="primary" style={{marginBottom: 24}}>
                      {t('system.whoopsMessage')}
                    </Typography>
                    {this.props.error.level !== 0 &&
                      <Button variant="outlined" size="large"
                        color="default" 
                        onClick={this.handleErrorCancel}
                        style={{marginRight: 8}}
                      >
                        {t('system.cancel')}
                      </Button>
                    }
                    <Button variant="contained" size="large"
                      color="secondary"
                      onClick={this.handleErrorRetry}
                    >
                      {t('system.tryAgain')} <RefreshIcon style={{marginLeft: 5}} />
                    </Button>
                    {this.props.error.message && this.props.error.message.length > 0 &&
                      <div style={{marginTop: 24, marginBottom: 24}}>
                        <Typography variant="overline" onClick={this.handleToggleErrorDetail} style={{cursor: 'pointer'}}>
                          {!showErrorDetail ? 'show' : 'hide' } error details {/*!showErrorDetail ? <AddOutlined size={6} /> : <RemoveOutlined size={6} /> */}
                        </Typography>
                        <Collapse in={showErrorDetail}>
                          <Typography variant="body2" style={{marginTop: 8}}>
                            { Array.isArray(this.props.error.message) ? 
                              this.props.error.message[0].message 
                            :
                              this.props.error.message
                            }
                          </Typography>                        
                        </Collapse>
                      </div>
                    }
                  </React.Fragment>
                }

              </Fade>
            </div>
          </div>        
        </Fade> 
      </React.Fragment>

    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    zIndex:10,
    //zIndex:1000
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    //marginLeft: 240,
    textAlign: 'center',
  },
  logoWrapper: {
    position: 'relative',
    width: 225,
    height: 60,
    marginBottom: 24,
  },
  logoLayer1: {
    position: 'absolute',
    backgroundImage: 'url("/langify-logo.png")',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    opacity: .45,
  },
  logoLayer2: {
    position: 'absolute',
    backgroundImage: 'url("/langify-logo.png")',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    width: '100%',
    height: '100%',
    opacity: 0,
  },
  circularProgress: {
    color: '#ccc', 
    margin: 24
  },
});

export default withStyles(styles)(withTranslation()(withSnackbar(LoadingIndicator)));